<template>
  <div>
    <!-- Filters -->
    <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8" actionButtons @refresh="resetColFilters">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full">
          <label class="text-sm opacity-75">Vehicle Type</label>
          <v-select :options="filters.options.vehicleType" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="filters.selected.vehicleType" class="mb-4 md:mb-0" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full flex justify-end">
          <vs-button class="md:mt-6 ml-3" color="success" type="border" icon-pack="feather" icon="icon-plus"
            @click="handleNewBrand">New Brand</vs-button>
        </div>
      </div>
    </vx-card>

    <vs-table :sst="true" @search="handleSearch" @sort="handleSort" v-model="selectedRow" :max-items="searching.limit"
      search :data="itemsData">
      <template slot="header">
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              ">
              <span class="mr-2">{{ searching.limit }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetPageSize(5)">
                <span>5</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(40)">
                <span>40</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </template>

      <template slot="thead">
        <vs-th sort-key="id">Logo</vs-th>
        <vs-th sort-key="createdDate">Title</vs-th>
        <vs-th sort-key="total">Title EN</vs-th>
        <vs-th sort-key="serviceId">Priority</vs-th>
        <vs-th>isCar</vs-th>
        <vs-th>isMotorcycle</vs-th>
        <vs-th>Status</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="data[indextr].id" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].id">
            <img :src="data[indextr].logoUrl" alt="" srcset="" class="h-10" />
          </vs-td>
          <vs-td :data="data[indextr].title">
            {{ data[indextr].title }}
          </vs-td>
          <vs-td :data="data[indextr].title_en">
            {{ data[indextr].title_en }}
          </vs-td>
          <vs-td :data="data[indextr].priority">
            {{ data[indextr].priority || 0 }}
          </vs-td>
          <vs-td :data="data[indextr].isCar">
            <vs-checkbox disabled v-model="data[indextr].isCar" @change="handleCheckbox(data[indextr])" />
          </vs-td>
          <vs-td :data="data[indextr].isMotorcycle">
            <vs-checkbox disabled v-model="data[indextr].isMotorcycle" @change="handleCheckbox(data[indextr])" />
          </vs-td>
          <vs-td :data="data[indextr].priority">
            <vs-chip class="ag-grid-cell-chip" :color="
              data[indextr].isCar || data[indextr].isMotorcycle
                ? 'success'
                : 'warning'
            ">
              <span>{{
                data[indextr].isCar || data[indextr].isMotorcycle
                  ? "Active"
                  : "Inactive"
              }}</span>
            </vs-chip>
          </vs-td>
          <vs-td>
            <div class="text-center">
              <feather-icon icon="EditIcon" svgClasses="h-5 w-5 hover:text-success cursor-pointer"
                @click="handleEditRecord(data[indextr])" />
              <!-- <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" /> -->
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="pt-2 flex justify-between">
      <div>
        <p>
          Page: {{ searching.page }} of {{ totalPagination }} | Total:
          {{ totalRecord }}
        </p>
      </div>
      <div>
        <vs-pagination :total="totalPagination" v-model="searching.page"></vs-pagination>
      </div>
    </div>

    <!-- Prompt -->
    <vs-prompt :title="formTitle" color="success" @cancel="resetBrandForm()" @close="resetBrandForm()"
      @accept="!isEdit ? handleCreate() : handleUpdate()" :is-valid="formValid" :active.sync="popupFormActive">
      <div class="mb-4">
        <label class="text-md">Title</label>
        <vs-input size="large" placeholder="Input title" v-model="form.title" class="w-full"
          name="lastname" v-validate="'required'" />
      </div>
      <div class="mb-2">
        <label class="text-md">Title EN</label>
        <vs-input size="large" placeholder="Input title en" v-model="form.title_en" class="w-full"
          name="lastname" v-validate="'required'" />
      </div>
      <div class="mb-2">
        <label class="text-md">Priority</label>
        <vs-input size="large" maxlength="3" oninput="this.value = this.value.replace(/[^0-9]/, '').replace(/^(0)/, '')"
          placeholder="Input priority" v-model="form.priority" class="w-full" name="lastname" v-validate="'required'" />
      </div>
      <div class="mb-2">
        <label class="text-md">Vehicle Type</label>
        <vs-checkbox v-model="form.isCar" class="mt-2">Is Car</vs-checkbox>
        <vs-checkbox v-model="form.isMotorcycle" class="mt-2">Is Motorcycle</vs-checkbox>
      </div>
      <div class="mb-2">
        <label class="text-md">Logo</label>
        <!-- Upload image -->
        <div class="upload-wrapper flex flex-col justify-center items-center mt-2">
          <div
            class="preview-wrapper flex justify-center items-center border-1 border-dotted border-grey p-4 shadow rounded-lg min-h-[10rem] min-w-[10rem] mb-4 relative">
            <feather-icon v-if="isShowLogoPreview" icon="XIcon"
              class="close-icon p-1 bg-grey text-white rounded-lg cursor-pointer transition duration-200 hover:bg-danger hover:rounded-full hover:shadow-md"
              @click="handleResetFile" />
            <span v-if="!isShowLogoPreview" class="text-gray flex flex-col justify-center items-center gap-2"> <feather-icon
                icon="ImageIcon" svgClasses="h-16 w-16" /> Preview</span>
            <img v-else :src="previewLogo || form.logoUrl" alt="" srcset="" class="m-auto h-32" />
          </div>
          <input ref="fileLogo" type="file" @change="handleFileUpload($event)" class="hidden"
            accept="image/png, image/gif, image/jpeg, image/jpg, image/webp" />
          <div class="flex gap-2 border-1 border-dotted p-3 rounded-lg cursor-pointer hover:shadow-md"
            @click="$refs.fileLogo.click()">
            <feather-icon icon="UploadCloudIcon" />Select Logo
          </div>
        </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VehiclePlace from "@/views/pages/information/components/VehiclePlace";
import CarCard from "@/views/pages/information/components/CarCard";
import TagCard from "@/views/pages/information/components/TagCard";
import vSelect from "vue-select";
import alerts from "@/mixins/alerts";
import upload from "@/mixins/upload";
const baseUrl = `${process.env.VUE_APP_API_DOMAIN}/static/images/brands-logo`;

export default {
  name: "batch-list",
  mixins: [alerts, upload],
  components: {
    vSelect,
    CarCard,
    TagCard,
    VehiclePlace,
  },
  data: () => ({
    popupFormActive: false,
    form: {
      title: null,
      title_en: null,
      isCar: false,
      isMotorcycle: false,
      priority: 0,
      logoUrl: '',
    },
    searching: {
      limit: 10,
      page: 1,
      searchAdvance: {},
      sort: "title_en:asc",
    },
    selectedRow: [],
    log: [],
    filters: {
      selected: {
        vehicleType: { label: "All", value: "all" },
      },
      options: {
        vehicleType: [
          { label: "All", value: "all" },
          { label: "Car", value: "car" },
          { label: "Motorcycle", value: "motorcycle" },
        ],
      },
    },
    isEdit: false,
    previewLogo: null,
    fileLogo: '',
  }),
  created() {
    this.fetchBrands(this.searching);
  },
  computed: {
    ...mapGetters({
      brands: "masterData/brands",
    }),
    itemsData() {
      return this.$store.state.masterData.brands.rows
        ? JSON.parse(JSON.stringify(this.$store.state.masterData.brands.rows))
        : [];
    },
    totalPagination() {
      return +Math.ceil(
        this.$store.state.masterData.brands.total / this.searching.limit
      );
    },
    totalRecord() {
      return this.$store.state.masterData.brands.total || 0;
    },
    formValid() {
      if (this.form.title && this.form.title_en) return true;
      else return false;
    },
    formTitle() {
      return this.isEdit ? "Edit form" : "Add form";
    },
    isShowLogoPreview() {
      return this.form.logoUrl || this.previewLogo
    }
  },
  watch: {
    itemsData() {
      this.selectedRow = [];
    },
    "searching.page"() {
      this.fetchBrands(this.searching);
    },
    "searching.limit"() {
      this.fetchBrands(this.searching);
    },
    "filters.selected.vehicleType"() {
      let status = this.filters.selected.vehicleType.value;
      delete this.searching.searchAdvance.isCar;
      delete this.searching.searchAdvance.isMotorcycle;
      switch (status) {
        case "car":
          this.searching.searchAdvance.isCar = { type: "eq", value: 1 };
          break;
        case "motorcycle":
          this.searching.searchAdvance.isMotorcycle = {
            type: "eq",
            value: 1,
          };
          break;
        default:
          break;
      }
      this.fetchBrands(this.searching);
    },
    "filters.selected.service"() {
      let serviceId = this.filters.selected.service.value;
      switch (serviceId) {
        case "all":
          this.searching.searchAdvance.serviceId = {};
          break;
        default:
          this.searching.searchAdvance.serviceId = {
            type: "eq",
            value: serviceId,
          };
          break;
      }
      this.fetchBrands(this.searching);
    },
  },
  methods: {
    ...mapActions({
      fetchBrands: "masterData/getBrands",
      createBrand: "masterData/createBrand",
      updateBrand: "masterData/updateBrand",
      deleteBrand: "masterData/deleteBrand",
    }),
    showLogoUrl(name) {
      return `${baseUrl}/${name}`;
    },
    handleSearch(text) {
      this.searching.page = 1;
      this.searching.searchAdvance = {}
      if (text)
        this.searching.searchAdvance = {
          title: { type: "like", value: text },
          title_en: { type: "like", value: text },
        };
      this.fetchBrands(this.searching);
    },
    handleSort(key, active) {
      if (active) {
        this.searching.searchAdvance = {
          sort: `${key}|${active}`,
          ...this.searching.searchAdvance,
        };
        this.fetchBrands(this.searching);
      }
    },
    confirmDeleteRecord() { },
    handleEditRecord(item) {
      this.isEdit = true;
      this.form = Object.assign(this.form, item);
      this.popupFormActive = true;
    },
    paginationSetPageSize(val) {
      this.searching.limit = val;
    },
    resetColFilters() {
      // Reset Filter Options
      this.filters.selected.status = { label: "All", value: "all" };
      this.filters.selected.service = { label: "All", value: "all" };
      this.$refs.filterCard.removeRefreshAnimation();
    },
    async handleCreate() {
      try {
        this.$vs.loading();
        if (this.fileLogo) {
          this.form.logoUrl = await this.getLogoUrl()
        }
        const res = await this.createBrand(this.form);
        console.log(res);
        this.showSuccess("Created brand Success");
        this.fetchBrands(this.searching);
        this.resetBrandForm();
      } catch (error) {
        this.showError(error.message);
      } finally {
        this.$vs.loading.close();
      }
    },
    async handleUpdate() {
      try {
        this.$vs.loading();
        const id = this.form.id;
        delete this.form.id;
        this.form.logoUrl && await this.deleteImage(this.form.logoUrl)
        if (this.fileLogo) {
          this.form.logoUrl = await this.getLogoUrl()
        }
        await this.updateBrand({
          id,
          ...this.form,
        });
        this.showSuccess("Updated brand Success");
        this.fetchBrands(this.searching);
      } catch (error) {
        this.showError(error.message);
      } finally {
        this.resetBrandForm();
        this.$vs.loading.close();
      }
    },
    handleCheckbox() {
      // this.updateBrand({
      //   id: item.id,
      //   isCar: item.isCar ? 1 : 0,
      //   isMotorcycle: item.isMotorcycle ? 1 : 0,
      // }).then(() => {
      //   this.showSuccess(`Update ${item.title_en} Success`);
      //   this.fetchBrands(this.searching);
      // });
    },
    handleNewBrand() {
      this.popupFormActive = true;
      this.sEdit = false;
    },
    resetBrandForm() {
      this.form.title = "";
      this.form.title_en = "";
      this.form.isCar = false;
      this.form.isMotorcycle = false;
      this.form.priority = 0;
      this.form.logoUrl = "";
      this.fileLogo = "";
      this.previewLogo = "";
    },
    async getLogoUrl() {
      const data = new FormData();
      data.append('file', this.fileLogo);
      try {
        const { url } = await this.uploadImage(data)
        return url
      } catch (error) {
        return Promise.reject(error)
      }
    },
    handleResetFile() {
      this.fileLogo = ''
      this.previewLogo = ''
    },
    handleFileUpload(e) {
      this.fileLogo = event.target.files[0]
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.previewLogo = e.target.result;
      };
    },
  },
};
</script>

<style lang="scss" scpoped>
.upload-wrapper {
  .preview-wrapper {
    min-height: 10rem;
    min-width: 10rem;

    .feather-icon.close-icon {
      position: absolute !important;
      top: -8px;
      right: -8px;
    }
  }
}

.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success), 0.15);
    color: rgba(var(--vs-success), 1) !important;
    font-weight: 500;
  }

  &.vs-chip-warning {
    background: rgba(var(--vs-warning), 0.15);
    color: rgba(var(--vs-warning), 1) !important;
    font-weight: 500;
  }

  &.vs-chip-danger {
    background: rgba(var(--vs-danger), 0.15);
    color: rgba(var(--vs-danger), 1) !important;
    font-weight: 500;
  }
}
</style>
