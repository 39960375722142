// axios
import axios from "axios"

const config = {
  baseURL: process.env.VUE_APP_S3_URL,
  timeout: process.env.VUE_APP_AXIOS_TIMEOUT,
  headers: {
    'ss-api-key': process.env.VUE_APP_S3_API_KEY
  }
}

export default axios.create(config)
