import axios from "../http/axios/upload";
export default {
  methods: {
    async uploadImage(formData) {
      try {
        const { data } = await axios.post("/image/upload", formData);
        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteImage(url) {
      try {
        const { data } = await axios.delete("/image", {
          data: { url },
        });
        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
